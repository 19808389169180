export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [0,5,6,7];

export const dictionary = {
		"/(marketing)": [47,[9]],
		"/(single-page)/auth/change-password": [49,[11]],
		"/(single-page)/auth/reset-password": [50,[11]],
		"/(single-page)/auth/verify": [~51,[11]],
		"/(app)/dashboard": [~12,[2,3]],
		"/(app)/dashboard/account/[id]": [~13,[2,3]],
		"/(app)/dashboard/admin": [~14,[2,3,4]],
		"/(app)/dashboard/admin/assignments": [~15,[2,3,4]],
		"/(app)/dashboard/admin/classifiers": [~16,[2,3,4]],
		"/(app)/dashboard/admin/organisations": [~17,[2,3,4]],
		"/(app)/dashboard/admin/users": [~18,[2,3,4]],
		"/(app)/dashboard/assignments": [~19,[2,3]],
		"/(app)/dashboard/assignments/[id]": [20,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/automated-feedback": [21,[2,3,5,6]],
		"/(app)/dashboard/assignments/[id]/automated-feedback/examples/[exampleId]": [~22,[2,3,5,6]],
		"/(app)/dashboard/assignments/[id]/automated-feedback/new-example": [23,[2,3,5,6]],
		"/(app)/dashboard/assignments/[id]/groups": [~24,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/settings": [~25,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/simulator": [~26,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/submissions": [~27,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/submissions/[sub_id]": [~28,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/tasks": [~29,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/users": [~30,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/users/invite": [31,[2,3,5]],
		"/(app)/dashboard/classifiers": [~32,[2,3]],
		"/(app)/dashboard/classifiers/[id]": [33,[2,3,7]],
		"/(app)/dashboard/classifiers/[id]/groups": [~34,[2,3,7]],
		"/(app)/dashboard/classifiers/[id]/labels": [35,[2,3,7]],
		"/(app)/dashboard/classifiers/[id]/settings": [~36,[2,3,7]],
		"/(app)/dashboard/classifiers/[id]/train-open": [~37,[2,3,7]],
		"/(app)/dashboard/classifiers/[id]/training-files": [~38,[2,3,7]],
		"/(app)/dashboard/classifiers/[id]/users": [~39,[2,3,7]],
		"/(app)/dashboard/groups": [~40,[2,3]],
		"/(app)/dashboard/invitations/created/[invitationId]": [~41,[2,3]],
		"/(app)/dashboard/organisations/[id]": [~42,[2,3]],
		"/(single-page)/login": [52,[11]],
		"/(onboarding)/registration/register/[invitationId]": [~48,[10]],
		"/(app)/student": [~43,[2,8]],
		"/(app)/student/account/[id]": [~44,[2,8]],
		"/(app)/student/assignments": [~45,[2,8]],
		"/(app)/student/assignments/[sub_id]": [~46,[2,8]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';